// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../controllers"
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage"
import "channels"

ActiveStorage.start()


require("@fortawesome/fontawesome-free");
require ("moment");
require('bootstrap');
require('@popperjs/core')
require('choices.js')
require('qr-scanner')
require('@client-side-validations/client-side-validations')
require('./nested-forms/add_fields');
require('./nested-forms/remove_fields');
require('../shared/form_errors');






